module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thomas rutzer — expert in crafting unique interfaces & interactions for the browser platform","short_name":"thomas rutzer — Javascript Developer","start_url":"/","background_color":"hsl(195, 7.1%, 11.0%)","theme_color":"hsl(195, 7.1%, 11.0%)","display":"standalone","icon":"src/images/icon.png","shortcuts":[{"name":"Go to archive","short_name":"archive","description":"(Almost all) Design & Code along the road","url":"/project-archive"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5183ec55dace79aa8fcbdf453b4dadd4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
